<template>
  <div class="financial-accounts" v-if="canView">
    <page-header :title="title" :icon="icon" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <div v-if="isLoaded">
      <b-row>
        <b-col cols="6">
          <b-form-group description="Ne voir que les comptes incluant ce texte">
            <b-form-input v-model="filterValue" type="text" placeholder="Filtrer les comptes">
            </b-form-input>
          </b-form-group>
          <b-form-checkbox id="orderByPriority" v-model="orderByPriority" v-if="isAnalytic">
            Trier par priorité
          </b-form-checkbox>
        </b-col>
        <b-col cols="6">
          <b-form-group description="Ne voir que les comptes du groupe" v-if="isAnalytic">
            <b-form-input v-model="filterGroup" type="text" placeholder="Filtrer par groupe">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div v-if="isLoaded && isAnalytic">
      <b-row>
        <b-col cols="3">
          <b-select v-model="viewUsage">
            <b-select-option :value="0">Tous les analytiques</b-select-option>
            <b-select-option :value="1">Analytiques utilisés sur les années sélectionnées</b-select-option>
            <b-select-option :value="2">Analytiques non-utilisés sur les années sélectionnées</b-select-option>
          </b-select>
        </b-col>
        <b-col>
          <div v-show="viewUsage">
            <active-school-years-multi-select v-model="selectedSchoolYears" inline :loaded-school-years="schoolYears">
            </active-school-years-multi-select>
          </div>
        </b-col>
        <b-col cols="3" class="text-right small-text">
          <counter-label :counter="selection.length" label="sélectionné"></counter-label> /
          <counter-label :counter="filteredObjects.length" label="visible"></counter-label> /
          <counter-label :counter="objects.length" label="au total" label-n="au total"></counter-label>
        </b-col>
      </b-row>
    </div>
    <div ref="printMe" v-if="isLoaded">
      <b-row>
        <b-col cols="12" ref="excelMe">
          <table class="table-full table-striped table-bordered table-accounts">
            <tr class="row-header">
              <th v-if="isAnalytic" class="no-print">
                <b-checkbox id="'selectAll'" :checked="isAllSelected" @change="toggleAllSelection">
                </b-checkbox>
              </th>
              <th>Code</th>
              <th>Libellé</th>
              <th>Ordre</th>
              <th v-if="isAnalytic">Priorité</th>
              <th v-if="hasShowYearChoice">Affichage</th>
              <th>Ventes</th>
              <th v-if="isAnalytic">Groupe</th>
              <th class="no-print" style="width: 120px;"></th>
            </tr>
            <tr v-if="filteredObjects.length === 0">
              <td :colspan="isAnalytic ? 7 : 5" class="empty-text">Aucun élement</td>
            </tr>
            <tr v-for="obj of filteredObjects" :key="obj.id">
              <td v-if="isAnalytic" class="no-print">
                <b-checkbox :id="'select' + obj.id" :checked="isSelected(obj)" @change="toggleObj(obj)">
                </b-checkbox>
              </td>
              <td>{{ obj.name }}</td>
              <td>{{ obj.label }}</td>
              <td>{{ obj.order }}</td>
              <td v-if="isAnalytic">
                <span v-if="obj.priority">{{ obj.priority }}</span>
              </td>
              <td v-if="hasShowYearChoice">
                <span class="badge badge-light">{{ obj.getDisplayMode() }}</span>
              </td>
              <td>
                <div v-for="schoolYear of schoolYears" :key="schoolYear.id" class="sales-stats">
                  <div v-if="getCount(schoolYear, obj)">
                    {{ schoolYear.name }}:
                    <span class="sales-price">{{ getPrice(schoolYear, obj) | currency }}</span>
                    <span class="sales-counter">{{ getCount(schoolYear, obj) }}</span>
                  </div>
                </div>
              </td>
              <td v-if="isAnalytic">
                <span v-if="obj.group">{{ obj.group.name }}</span>
              </td>
              <td class="text-right no-print">
                <div class="show-hover">
                  <edit-account-button :model-name="modelName" :account="obj" @done="onLoaded()">
                  </edit-account-button>
                  &nbsp;
                  <delete-account-button :model-name="modelName" :account="obj" @done="onLoaded()">
                  </delete-account-button>
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <add-account-modal
      :modal-id="addModalId"
      :model-name="modelName"
      @done="onLoaded()"
    ></add-account-modal>
    <confirm-modal
      v-if="isAnalytic"
      name="remove-analytics-accounts"
      title="Supprimer des comptes analytiques"
      :text="'Souhaitez-vous cacher le' + (selection.length ? 's comptes sélectionnés' : ' compte sélectionné') + '?'"
      @confirmed="confirmRemoveAccount()"
    ></confirm-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DeleteAccountButton from '@/components/Accounting/DeleteAccountButton'
import AddAccountModal from '@/components/Accounting/AddAccountModal'
import EditAccountButton from '@/components/Accounting/EditAccountButton'
import ActiveSchoolYearsMultiSelect from '@/components/SchoolYears/ActiveSchoolYearsMultiSelect.vue'
import { makeAnalyticAccount, makeGeneralAccount } from '@/types/payments'
import { makeSchoolYear } from '@/types/schools'
import { BackendApi, openDocument } from '@/utils/http'
import { includes } from '@/utils/strings'
import { existsIn } from '@/utils/arrays'
import { compareStrings, compareNumbers } from '@/utils/sorting'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'

export default {
  name: 'AnalyticAccounts',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    CounterLabel,
    ActiveSchoolYearsMultiSelect,
    AddAccountModal,
    DeleteAccountButton,
    EditAccountButton,
    LoadingGif,
    PageHeader,
  },
  props: {
    modelName: String,
  },
  data() {
    return {
      objects: [],
      loadingName: 'financialAccountsLoading',
      addModalId: 'bv-modal-add-financial-account',
      stats: new Map(),
      filterValue: '',
      filterGroup: '',
      schoolYears: [],
      orderByPriority: false,
      selectedSchoolYears: [],
      viewUsage: 0,
      usages: [],
      selection: [],
    }
  },
  watch: {
    modelName: function() {
      this.onLoaded()
    },
    viewUsage: function() {
    },
    selectedSchoolYears: function() {
      this.loadUsages()
    },
  },
  computed: {
    isAnalytic() {
      return this.modelName === 'analytic'
    },
    isGeneral() {
      return this.modelName === 'general'
    },
    title() {
      return this.isAnalytic ? 'Comptes analytiques' : 'Comptes généraux'
    },
    icon() {
      return 'fas fa-table'
    },
    isLoaded() {
      return !this.isLoading(this.loadingName)
    },
    canView() {
      if (this.isAnalytic) {
        return this.hasPerm('payments.view_analyticaccount')
      } else if (this.isGeneral) {
        return this.hasPerm('payments.view_generalaccount')
      }
      return false
    },
    hasShowYearChoice() {
      return this.modelName === 'analytic'
    },
    filteredObjects() {
      let objects = this.objects
      if (this.filterValue) {
        objects = objects.filter(
          elt => includes(elt.name, this.filterValue) || includes(elt.label, this.filterValue)
        )
      }
      if (this.isAnalytic && this.filterGroup) {
        objects = objects.filter(
          elt => elt.group && includes(elt.group.name, this.filterGroup)
        )
      }
      if (this.isAnalytic && this.viewUsage) {
        if (this.viewUsage === 1) {
          // utilisés
          objects = objects.filter(elt => existsIn([elt.id], this.usages))
        }
        if (this.viewUsage === 2) {
          // non-utilisés
          objects = objects.filter(elt => !existsIn([elt.id], this.usages))
        }
      }
      return objects.sort(
        (obj1, obj2) => {
          if (this.orderByPriority) {
            if (obj1.priority !== obj2.priority) {
              const priority1 = obj1.priority || 9999999
              const priority2 = obj2.priority || 9999999
              return compareNumbers(priority1, priority2)
            }
          }
          if (obj1.order !== obj2.order) {
            return compareNumbers(obj1.order, obj2.order)
          }
          return compareStrings(obj1.name, obj2.name)
        }
      )
    },
    isAllSelected() {
      return this.selection.length === this.filteredObjects.length
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      this.startLoading(this.loadingName)
      await this.loadSchoolYears()
      await this.loadObjects()
      await this.loadSalesStats()
      this.endLoading(this.loadingName)
    },
    async loadObjects() {
      let url = ''
      let maker = null
      if (this.isAnalytic) {
        url = '/api/analytic-accounts/'
        maker = makeAnalyticAccount
      } else if (this.isGeneral) {
        url = '/api/general-accounts/'
        maker = makeGeneralAccount
      }
      if (maker === null) {
        return
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.selection = []
        this.objects = resp.data.map(elt => maker ? maker(elt) : elt)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadUsages() {
      const years = this.selectedSchoolYears.map(year => '' + year.id).join('-')
      if (years) {
        let url = '/tools/api/analytics-usages/?years=' + years
        const backendApi = new BackendApi('get', url)
        try {
          const resp = await backendApi.callApi()
          this.usages = resp.data.map(elt => elt.id)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.usages = []
      }
    },
    async loadSchoolYears() {
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        ).filter(
          elt => elt.isActive
        )
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadSalesStats() {
      this.stats = new Map()
      const url = '/api/sales-stats/' + this.modelName + '_account/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        for (const elt of resp.data) {
          const key = '' + elt['school_year'] + '-' + elt['group_by']
          this.stats.set(key, [elt.price, elt.count])
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    addAccount() {
      this.$bvModal.show(this.addModalId)
    },
    getCount(schoolYear, obj) {
      const key = '' + schoolYear.id + '-' + obj.id
      if (this.stats.has(key)) {
        return this.stats.get(key)[1]
      }
      return null
    },
    getPrice(schoolYear, obj) {
      const key = '' + schoolYear.id + '-' + obj.id
      if (this.stats.has(key)) {
        return this.stats.get(key)[0]
      }
      return null
    },
    getLinks() {
      const links = []
      links.push(
        {
          id: 2,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      links.push(
        {
          id: 3,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      )
      if (this.hasPerm('payments.add_' + this.modelName + 'account')) {
        links.push(
          {
            id: 1,
            label: 'Ajouter',
            callback: this.addAccount,
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      if (this.isAnalytic && this.hasPerm('payments.delete_' + this.modelName + 'account')) {
        links.push(
          {
            id: 4,
            label: 'Supprimer',
            callback: this.deleteAccounts,
            icon: 'fa fa-trash',
            cssClass: this.selection.length ? 'btn-danger' : 'btn-danger disabled',
          }
        )
      }
      return links
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = this.modelName + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = this.modelName + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    toggleAllSelection() {
      if (this.isAllSelected) {
        this.selection = []
      } else {
        this.selection = this.filteredObjects.map(elt => elt.id)
      }
    },
    isSelected(obj) {
      return this.selection.indexOf(obj.id) >= 0
    },
    toggleObj(obj) {
      const index = this.selection.indexOf(obj.id)
      if (index < 0) {
        this.selection.push(obj.id)
      } else {
        this.selection.splice(index, 1)
      }
      this.selection = [].concat(this.selection)
    },
    deleteAccounts() {
      this.$bvModal.show('bv-confirm-modal:remove-analytics-accounts')
    },
    async confirmRemoveAccount() {
      this.startLoading(this.loadingName)
      let hasError = false
      for (const sel of this.selection) {
        const url = '/api/' + this.modelName + '-accounts/' + sel + '/'
        let backendApi = new BackendApi('delete', url)
        try {
          await backendApi.callApi()
        } catch (err) {
          await this.addError(this.getErrorText(err))
          hasError = true
          break
        }
      }
      if (!hasError) {
        if (this.selection === 1) {
          await this.addSuccess('Le compte a été supprimé')
        } else {
          await this.addSuccess('Les comptes ont été supprimés')
        }
      } else {
        await this.addWarning('La suppression a été interrompue')
      }
      this.selection = []
      this.endLoading(this.loadingName)
      await this.onLoaded()
    },
  },
}
</script>

<style lang="less">
.sales-stats {
  display: block;
  font-size: 13px;
  margin-bottom: 2px;
}
.sales-counter {
  margin: 0 2px;
  padding: 1px 5px;
  border: solid 1px #888;
  border-radius: 4px;
  font-size: 12px;
}
.table-accounts td, .table-accounts th {
  padding: 5px;
}
tr td .show-hover {
  display: none;
}
tr:hover td .show-hover {
  display: block;
}
.table-accounts tr:hover td {
  background: #ccc !important;
}
.table-full {
  width: 100%;
}
</style>
